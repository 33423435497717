<mat-card class="no-padding">
  <mat-card-header>
    <mat-card-title>{{ heading }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <img
      mat-card-image
      *ngIf="imageUrl; else text"
      [src]="imageUrl"
      onerror="this.src='assets/img/wrong-placeholder.png'"
    />
    <ng-template #text>
      <div class="container">
        <ng-content></ng-content>
      </div>
    </ng-template>
  </mat-card-content>

  <mat-card-footer>
    <button [routerLink]="link" mat-stroked-button color="accent">
      Details
    </button>
  </mat-card-footer>
</mat-card>
