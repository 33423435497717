/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from '@design-system/feature/app-wrapper';

@Component({
  selector: 'ds-docu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  menu: Menu = {
    title: 'Design System',
    titleIcon: 'color_lens',
    navigation: [
      {
        title: 'Search',
        icon: 'search',
        routerLink: '/landing',
      },
      {
        title: 'Setup',
        icon: 'build',
        routerLink: '/setup',
        children: [
          {
            title: 'Overview',
            routerLink: '/setup/overview',
          },
          {
            title: 'Setup',
            routerLink: '/setup/setup',
          },
          {
            title: 'Modal vs Page',
            routerLink: '/setup/modal-decision',
          },
          {
            title: 'Special Pages',
            routerLink: '/setup/page-types',
            children: [
              {
                title: 'Special Pages Setup',
                routerLink: '/setup/page-types/setup',
              },
              {
                title: 'Help Pages',
                routerLink: '/setup/page-types/help-pages',
              },
              {
                title: 'Page not found (404)',
                routerLink: '/setup/page-types/special/pagenotfound',
              },
              {
                title: 'Access denied page (403)',
                routerLink: '/setup/page-types/special/permissiondenied',
              },
              {
                title: 'Sign out page (401)',
                routerLink: '/setup/page-types/special/signout',
              },
            ],
          },
          {
            title: 'DS Process',
            routerLink: '/setup/process',
            children: [
              {
                title: 'Scrum',
                routerLink: '/setup/process/scrum',
              },
              {
                title: 'Feedback',
                routerLink: '/setup/process/feedback',
              },
              {
                title: 'Ideas',
                routerLink: '/setup/process/ideas',
              },
            ],
          },
        ],
      },
      {
        title: 'Foundation',
        icon: 'foundation',
        routerLink: '/foundation',
        children: [
          {
            title: 'Overview',
            routerLink: '/foundation/overview',
          },
          {
            title: 'Colors',
            routerLink: '/foundation/colors',
          },
          {
            title: 'Writing and Formating',
            routerLink: '/foundation/writing-and-formating',
            children: [
              {
                title: 'Basics',
                routerLink: '/foundation/writing-and-formating/basics',
              },
              {
                title: 'Questions and Answers',
                routerLink:
                  '/foundation/writing-and-formating/questions-and-answers',
              },
              {
                title: 'Read Only',
                routerLink: '/foundation/writing-and-formating/read-only',
              },
              {
                title: 'Date Formating',
                routerLink: '/foundation/writing-and-formating/date-formating',
              },

              {
                title: 'Currency Formating',
                routerLink:
                  '/foundation/writing-and-formating/currency-formating',
              },
            ],
          },
          {
            title: 'Spacing',
            routerLink: '/foundation/spacing',
          },
          {
            title: 'Event handling',
            routerLink: '/foundation/events',
            children: [
              {
                title: 'Loading event',
                routerLink: '/foundation/events/loading',
              },
              { title: 'Error event', routerLink: '/foundation/events/error' },
              {
                title: 'Success event',
                routerLink: '/foundation/events/success',
              },
            ],
          },
          {
            title: 'Navigation Basics',
            routerLink: '/foundation/navigation-basics',
          },
          {
            title: 'Angular Material',
            routerLink: '/foundation/angular-material',
          },
          {
            title: 'Typography',
            routerLink: '/foundation/typography',
          },
        ],
      },
      {
        title: 'Layout',
        icon: 'dashboard',
        routerLink: '/layout',
        children: [
          {
            title: 'Overview',
            routerLink: '/layout/overview',
          },
          {
            title: 'Responsive',
            routerLink: '/layout/responsive',
          },
          {
            title: 'Page Actions',
            routerLink: '/layout/page-actions',
          },
          {
            title: 'Flex Page',
            routerLink: '/layout/flex-page',
          },
          {
            title: 'Process Page',
            routerLink: '/layout/process-page',
          },
          {
            title: 'Sidebar',
            routerLink: '/layout/sidebar',
            onClick: () => {
              if (this._router.url.indexOf('/layout/sidebar') !== -1) {
                alert(
                  'You clicked sidebar, whilst already on the sidebar page.',
                );
              }
            },
          },
        ],
      },
      {
        title: 'Components',
        icon: 'spellcheck',
        routerLink: '/components',
        children: [
          {
            title: 'Overview',
            routerLink: '/components/overview',
          },
          {
            title: 'Buttons',
            routerLink: '/components/buttons',
          },
          {
            title: 'Code scanner',
            routerLink: '/components/code-scanner',
          },
          {
            title: 'Drawer',
            routerLink: '/components/drawer',
          },
          {
            title: 'Absolute Expansion Panel',
            routerLink: '/components/absolute-expansion-panel',
          },
          {
            title: 'Breadcrumbs',
            routerLink: '/components/breadcrumbs',
          },
          {
            title: 'Snackbar',
            routerLink: '/components/snackbar',
          },
          {
            title: 'Image',
            routerLink: '/components/images',
            children: [
              { title: 'Single', routerLink: '/components/images/single' },
              { title: 'Multiple', routerLink: '/components/images/multi' },
              { title: 'Upload', routerLink: '/components/images/upload' },
              {
                title: 'Carousel',
                routerLink: '/components/images/carousel',
              },
            ],
          },
          {
            title: 'Data Group/Pair',
            routerLink: '/components/data-group',
          },
          {
            title: 'Ribbon',
            routerLink: '/components/ribbon',
          },
          {
            title: 'Text Indicators',
            routerLink: '/components/text-indicators',
          },
          {
            title: 'Custom placehoder',
            routerLink: '/components/custom-placeholder',
          },
          {
            title: 'Map',
            routerLink: '/components/map',
          },
          {
            title: 'Attachments',
            routerLink: '/components/attachments',
          },
          {
            title: 'Card Carousel',
            routerLink: '/components/card-carousel',
          },
          {
            title: 'Table',
            routerLink: '/components/table',
            children: [
              {
                title: 'Basics',
                routerLink: '/components/table/basics',
              },
              {
                title: 'Scrolling',
                routerLink: '/components/table/scroll',
              },
              {
                title: 'Settings',
                routerLink: '/components/table/settings',
              },
              {
                title: 'Content',
                routerLink: '/components/table/content',
              },
              {
                title: 'Actions',
                routerLink: '/components/table/actions',
              },
              {
                title: 'Sticky',
                routerLink: '/components/table/sticky',
              },
              {
                title: 'Column groups',
                routerLink: '/components/table/column-groups',
              },
              {
                title: 'Row groups',
                routerLink: '/components/table/row-groups',
              },
              {
                title: 'Responsive',
                routerLink: '/components/table/responsive',
              },
              {
                title: 'Ultimate',
                routerLink: '/components/table/ultimate',
              },
              {
                title: 'Complex',
                routerLink: '/components/table/complex',
              },
            ],
          },
          {
            title: 'Icons',
            routerLink: '/components/icons',
          },
          {
            title: 'Spotlight',
            routerLink: '/components/spotlight',
          },
          {
            title: 'Comparison Table',
            routerLink: '/components/comparison-table',
          },
          {
            title: 'Signature',
            routerLink: '/components/signature',
          },
        ],
      },
      {
        title: 'Form Controls',
        icon: 'check_box',
        routerLink: '/form-controls',
        children: [
          {
            title: 'Overview',
            routerLink: '/form-controls/overview',
          },
          {
            title: 'Basics',
            routerLink: '/form-controls/basics',
          },
          {
            title: 'Validation',
            routerLink: '/form-controls/validation',
          },
          {
            title: 'Filter',
            routerLink: '/form-controls/filter',
          },
          {
            title: 'Filter with URL params',
            routerLink: '/form-controls/filter-url-params',
          },
          {
            title: 'Filter input',
            routerLink: '/form-controls/filter-input',
          },
          {
            title: 'Filter Range Input',
            routerLink: '/form-controls/filter-range-input',
          },
          {
            title: 'Advanced datepicker',
            routerLink: '/form-controls/advanced-datepicker',
          },
        ],
      },
      {
        title: 'CDK',
        icon: 'home_repair_service',
        routerLink: '/cdk',
        children: [
          {
            title: 'Overview',
            routerLink: '/cdk/overview',
          },
          {
            title: 'Print',
            routerLink: '/cdk/print',
          },
          {
            title: 'CSS Utils',
            routerLink: '/cdk/css-utils',
          },
          {
            title: 'Mixins',
            routerLink: '/cdk/mixins',
          },
        ],
      },
    ],
  };

  demoMenu: Menu = {
    title: 'Demo Sidebar',
    titleIcon: 'account_circle',
    backButtonUrl: '/layout/sidebar',
    navigation: [
      {
        title: 'Demo Documentation',
        icon: 'quiz',
        routerLink: '/layout/sidebar/demo',
        badge: '3',
      },
      {
        title: 'PK 300.0123',
        equipmentIcon: '001',
        routerLink: '/layout/sidebar/demo1',
        badge: 'star',
        isBadgeIcon: true,
      },
      {
        title: 'Badge icon',
        icon: 'business',
        routerLink: '/layout/sidebar/demo2',
        badge: 'home',
        isBadgeIcon: true,
        children: [
          {
            title: 'Some child',
            badge: 25,
          },
        ],
      },
      {
        title: 'Link to Paldesk',
        icon: 'home',
        externalLink: 'https://paldesk-test.palfinger.com/dashboard',
      },
      {
        title: 'Early access',
        icon: 'tips_and_updates',
        isHighlighted: true,
        onClick: () => {
          alert('You have just requested and early access to some feature');
        },
      },
    ],
  };

  constructor(
    public location: Location,
    private _router: Router,
  ) {}
}
