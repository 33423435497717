import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DsBannerModule } from '@design-system/components/banner';
import { TranslateModule } from '@ngx-translate/core';
import { HcStatusBannerComponent } from './hc-status-banner.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DsSpacingModule } from '@design-system/cdk/spacing';
@NgModule({
  imports: [
    CommonModule,
    DsBannerModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    DsSpacingModule,
  ],
  declarations: [HcStatusBannerComponent],
  exports: [HcStatusBannerComponent],
})
export class HcStatusBannerModule {}
