import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { HighlightModule } from 'ngx-highlightjs';

@Component({
  selector: 'ds-docu-code-snippet',
  templateUrl: './code-snippet.component.html',
  styleUrls: ['./code-snippet.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, HighlightModule],
})
export class CodeSnippetComponent {
  @Input() code: string;

  constructor(
    private readonly clipboard: Clipboard,
    private readonly snackbar: DsSnackbar,
  ) {}

  copySource(example: string) {
    if (this.clipboard.copy(example)) {
      this.snackbar.queue('Code copied', {
        type: DsSnackbarType.Success,
      });
    } else {
      this.snackbar.queue('Copy failed. Please try again!', {
        type: DsSnackbarType.Error,
      });
    }
  }
}
