<mat-card>
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
    <mat-card-title>{{ heading }} </mat-card-title>
    <button mat-icon-button type="button" (click)="toggleShowCode()">
      <mat-icon>code</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content [class.overflow-hidden]="isOverflowHidden">
    <mat-tab-group
      *ngIf="showCode"
      (selectedIndexChange)="getTextContent($event)"
    >
      <mat-tab
        *ngFor="let example of _examples; let i = index"
        [label]="example.type"
      >
        <ng-container *ngIf="exampleText$ | async as exampleText">
          <button
            mat-icon-button
            type="button"
            (click)="copySource(exampleText)"
            class="copy-button"
            matTooltip="Copy snippet"
            title="Copy example source"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
          <pre><code [highlight]="exampleText"></code></pre>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
    <div [class.example-bg]="hasBackground">
      <ng-content></ng-content>
    </div>
  </mat-card-content>
</mat-card>
