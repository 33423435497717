<h3>The team wishes you a Merry Christmas and a happy new year!</h3>
<span class="info-text"
  >Take a product christmas ball and decorate the tree!</span
>
<div class="scene">
  <div>
    <img
      #tree
      class="xmas-tree tree"
      src="assets/img/christmas_tree.png"
      draggable="false"
    />
  </div>
  <img class="logo" src="assets/img/logo.svg" />
  <div class="icons">
    <div *ngFor="let icon of icons">
      <div
        cdkDrag
        (cdkDragStarted)="started($event)"
        (cdkDragEnded)="drop($event)"
        cdkDragBoundary=".scene"
      >
        <div>
          <img class="icon" [src]="'assets/img/product-icons/' + icon" />
          <span class="rope"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<audio autoplay>
  <source src="assets/music/Jingle_Bells_full.mp3" type="audio/mpeg" />
</audio>
